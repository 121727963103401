// import React, { useEffect } from "react";
// import AOS from "aos";
// import "aos/dist/aos.css";
// import styles from "./Loading.module.css";

// function Loading() {
//   useEffect(() => {
//     AOS.init({
//       duration: 1000,
//       delay: 200, 
//     });
//   }, []);

//   return (
//     <div className="fixed inset-0 bg-black z-[9999] flex justify-center items-center">
//       <div className="text-white font-extrabold font-sans">
//         <span
//           data-aos="fade-up"
//           className="text-[48px] sm:text-[72px] md:text-[100px] lg:text-[150px] xl:text-[200px] 2xl:text-[240px]"
//         >
//           I
//         </span>
//         <span
//           data-aos="fade-up"
//           data-aos-delay="200"
//           className="text-[48px] sm:text-[72px] md:text-[100px] lg:text-[150px] xl:text-[200px] 2xl:text-[240px]"
//         >
//           N
//         </span>
//         <span
//           data-aos="fade-up"
//           data-aos-delay="400"
//           className="text-[48px] sm:text-[72px] md:text-[100px] lg:text-[150px] xl:text-[200px] 2xl:text-[240px]"
//         >
//           F
//         </span>
//         <span
//           data-aos="fade-up"
//           data-aos-delay="600"
//           className="text-[48px] sm:text-[72px] md:text-[100px] lg:text-[150px] xl:text-[200px] 2xl:text-[240px]"
//         >
//           I
//         </span>
//         <span
//           data-aos="fade-up"
//           data-aos-delay="800"
//           className="text-[48px] sm:text-[72px] md:text-[100px] lg:text-[150px] xl:text-[200px] 2xl:text-[240px]"
//         >
//           N
//         </span>
//         <span
//           data-aos="fade-up"
//           data-aos-delay="1000"
//           className="text-[48px] sm:text-[72px] md:text-[100px] lg:text-[150px] xl:text-[200px] 2xl:text-[240px]"
//         >
//           I
//         </span>
//         <span
//           data-aos="fade-up"
//           data-aos-delay="1200"
//           className="text-[48px] sm:text-[72px] md:text-[100px] lg:text-[150px] xl:text-[200px] 2xl:text-[240px]"
//         >
//           A
//         </span>
//       </div>
//     </div>
//   );
// }

// export default Loading;

// import React, { useEffect } from 'react';
// import image1 from "../assets/img/INFINIA_LOGO.png"
// import AOS from 'aos';
// import 'aos/dist/aos.css';

// const Loading = () => {
//     useEffect(() => {
//         AOS.init({
//             duration: 2000,
//             easing: 'ease-in-out',
//         });
//     }, []);

//     return (
//         <div className="flex justify-center items-center h-screen bg-white" data-aos="fade-up">
//             <img
//                 src={image1}
//                 alt="Loader"
//                 className="w-24 h-24 animate-spin"
//             />
//         </div>
//     );
// };

// export default Loading;
// import React, { useEffect } from 'react';
// import AOS from 'aos';
// import 'aos/dist/aos.css';
// import styled from 'styled-components';
// import image1 from "../assets/img/INFINIA LOGO top.png";

// const Loading = () => {
//     useEffect(() => {
//         AOS.init({
//             duration: 2000,
//         });
//     }, []);

//     return (
//         <DIV>
//             <div className="flex justify-center items-center h-screen bg-[#212121]">
//                 <img
//                     src={image1}
//                     alt="Loader"
//                     className="rotate-animation w-[300px] h-[200px]"  // Updated class name
//                 />
//             </div>
//         </DIV>
//     );
// };

// export default Loading;

// const DIV = styled.div`
// @keyframes rotate360 {
//     from {
//         transform: rotate(0deg);
//     }
//     to {
//         transform: rotate(360deg);
//     }
// }

// // .rotate-animation {
// //     animation: rotate360 2s linear infinite;
// // }
// // `;


// import React from 'react';
// import './Loading.css'; // Import the CSS file for styling

// const Loading = () => {
//   const text = "Loading...";
//   const rings = 2;
//   const ringSectors = 30;

//   return (
//     <div className="preloader">
//       {Array.from({ length: rings }).map((_, r) => (
//         <div key={r} className="preloader__ring">
//           {Array.from({ length: ringSectors }).map((_, s) => (
//             <div key={s} className="preloader__sector">
//               {text[s] || ""}
//             </div>
//           ))}
//         </div>
//       ))}
//     </div>
//   );
// };

// export default Loading;



// import React from 'react';
// import styled from 'styled-components';

// function Loading() {
//   return (
//     <DIV>
//       <div className="loader">
//         <span>I</span>
//         <span>N</span>
//         <span>F</span>
//         <span>I</span>
//         <span>N</span>
//         <span>A</span>
      
//       </div>
//     </DIV>
//   );
// }

// export default Loading;

// const DIV = styled.div`
//   height: 100vh;
//   width: 100vw;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   background-color: #212121;

//   .loader {
//     -webkit-perspective: 700px;
//     perspective: 700px;
//   }

//   .loader > span {
//     font-size: 200px;
//     font-family: 'franklin gothic medium', sans-serif;
//     display: inline-block;
//     animation: flip 2.6s infinite linear;
//     transform-origin: 0 70%;
//     transform-style: preserve-3d;
//     -webkit-transform-style: preserve-3d;
//   }

//   @keyframes flip {
//     35% {
//       transform: rotateX(360deg);
//     }
//     100% {
//       transform: rotateX(360deg);
//     }
//   }

//   .loader > span:nth-child(even) {
//     color: white;
//   }

//   .loader > span:nth-child(2) {
//     animation-delay: 0.3s;
//   }

//   .loader > span:nth-child(3) {
//     animation-delay: 0.6s;
//   }

//   .loader > span:nth-child(4) {
//     animation-delay: 0.9s;
//   }

//   .loader > span:nth-child(5) {
//     animation-delay: 1.2s;
//   }

//   .loader > span:nth-child(6) {
//     animation-delay: 1.5s;
//   }

//   .loader > span:nth-child(7) {
//     animation-delay: 1.8s;
//   }
// `;

// import React from 'react';
// import styled, { keyframes } from 'styled-components';

// const Loading = () => {
//   const textArray = ['I', 'N', 'F', 'I', 'N', 'I', 'A', '...'];

//   return (
//     <DIV>
//       <div className="wrap-loader">
//         <div className="loader">
//           {/* Loop to create 4 boxes */}
//           {[...Array(4)].map((_, index) => (
//             <div key={index} className="box"></div>
//           ))}
//         </div>
//         <div className="wrap-text">
//           <div className="text">
//             {/* Loop through textArray to render the letters */}
//             {textArray.map((val, index) => (
//               <span key={index}>{val}</span>
//             ))}
//           </div>
//         </div>
//         <div className="loader-text">wait please</div>
//       </div>
//     </DIV>
//   );
// };

// export default Loading;

// const lightMe1 = keyframes`
//   0% { opacity: 0.1; }
//   25% { opacity: 1; }
//   100% { opacity: 1; }
// `;

// const lightMe2 = keyframes`
//   0% { opacity: 0.1; }
//   25% { opacity: 0.1; }
//   50% { opacity: 1; }
//   100% { opacity: 1; }
// `;

// const lightMe3 = keyframes`
//   0% { opacity: 0.1; }
//   50% { opacity: 0.1; }
//   75% { opacity: 1; }
//   100% { opacity: 1; }
// `;

// const lightMe4 = keyframes`
//   0% { opacity: 0.1; }
//   75% { opacity: 0.1; }
//   100% { opacity: 1; }
// `;

// const slider = keyframes`
//   0% { transform: translateY(0); }
//   10.5% { transform: translateY(0); }
//   12.5% { transform: translateY(-40px); }
//   25% { transform: translateY(-80px); }
//   37.5% { transform: translateY(-120px); }
//   50% { transform: translateY(-160px); }
//   62.5% { transform: translateY(-200px); }
//   75% { transform: translateY(-240px); }
//   87.5% { transform: translateY(-280px); }
//   100% { transform: translateY(-320px); }
// `;

// const blink = keyframes`
//   from { opacity: 0.2; }
//   to { opacity: 0.75; }
// `;

// const DIV = styled.div`
//   --main-font: 'Exo', sans-serif;
//   --letterDim: 100px;
//   --letterSize: 5em;
//   --white: #fff;
//   --black: #000;
//   --palette1: #4185F3;
//   --palette2: #33A753;
//   --palette3: #FABB04;
//   --palette4: #E94234;
//   --dim: 20rem;
//   --speed: 2s;
//   --letterSpeed: 2s;

//   /* General styles */
//   *, *::before, *::after {
//     box-sizing: border-box;
//   }

//   body {
//     font-family: var(--main-font);
//     font-size: 16px;
//   }

//   .wrap-loader {
//     display: flex;
//     flex-direction: column;
//     justify-content: center;
//     align-items: center;
//     height: 100vh;
//     background: var(--black);
//   }

//   .loader {
//     position: relative;
//     width: var(--dim);
//     height: var(--dim);
//   }

//   .loader::before {
//     z-index: 1;
//     content: '';
//     position: absolute;
//     top: 50%;
//     left: 50%;
//     transform: translate(-50%, -50%);
//     width: calc(var(--dim) / 2.3);
//     height: calc(var(--dim) / 2.3);
//     background: var(--black);
//     border-radius: 50%;
//   }

//   .loader .box {
//     position: absolute;
//     width: calc(var(--dim) / 2);
//     height: calc(var(--dim) / 2);
//     overflow: hidden;
//   }

//   .loader .box:nth-child(1)::before {
//     top: 50%;
//     left: 50%;
//     background: #4185F3;
//     animation: ${lightMe1} var(--speed) ease-out infinite;
//   }

//   .loader .box:nth-child(2)::before {
//     top: 50%;
//     right: 50%;
//     background: var(--palette2);
//     animation: ${lightMe2} var(--speed) ease-out infinite;
//   }

//   .loader .box:nth-child(3)::before {
//     bottom: 50%;
//     right: 50%;
//     background: var(--palette3);
//     animation: ${lightMe3} var(--speed) ease-out infinite;
//   }

//   .loader .box:nth-child(4)::before {
//     bottom: 50%;
//     left: 50%;
//     background: var(--palette4);
//     animation: ${lightMe4} var(--speed) linear infinite;
//   }

//   .wrap-text {
//     z-index: 2;
//     position: absolute;
//     top: 50%;
//     left: 50%;
//     transform: translate(-50%, -50%);
//     width: var(--letterDim);
//     height: var(--letterDim);
//     overflow: hidden;
//   }

//   .text {
//     position: relative;
//     width: var(--letterDim);
//     height: var(--letterDim);
//     line-height: var(--letterDim);
//     animation: ${slider} var(--letterSpeed) ease-in infinite;
//   }

//   .text span {
//     display: block;
//     width: var(--letterDim);
//     height: var(--letterDim);
//     line-height: var(--letterDim);
//     text-align: center;
//     font-size: var(--letterSize);
//     color: var(--white);
//   }

//   .loader-text {
//     color: var(--white);
//     transform: translateY(-2rem);
//     letter-spacing: 6px;
//     opacity: 0.2;
//     animation: ${blink} calc(var(--speed) / 4) ease-out infinite alternate;
//   }
// `;


import React from 'react';
import styled from 'styled-components';

function Loading() {
  return (
    <DIV>
      <div className="loading-container">
        <div className="loading-text">
          <span>I</span>
          <span>N</span>
          <span>F</span>
          <span>I</span>
          <span>N</span>
          <span>I</span>
          <span>A</span>
        </div>
      </div>
      
    </DIV>
  );
}

export default Loading;

const DIV = styled.div`
  /* Container styles */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
  background-color: black; /* Set background to black */
  font-family: 'Montserrat', sans-serif;
  overflow: hidden;

  .loading-container {
    width: 100%;
    max-width: 520px;
    text-align: center;
    color: #fff;
    position: relative;
    margin: 0 32px;
  }

  .loading-container::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 3px;
    background-color: #fff;
    bottom: 0;
    left: 0;
    border-radius: 10px;
    animation: movingLine 2.4s infinite ease-in-out;
  }

  @keyframes movingLine {
    0% {
      opacity: 0;
      width: 0;
    }
    33.3%, 66% {
      opacity: 0.8;
      width: 100%;
    }
    85% {
      width: 0;
      left: initial;
      right: 0;
      opacity: 1;
    }
    100% {
      opacity: 0;
      width: 0;
    }
  }

  .loading-text {
    font-size: 5vw;
    line-height: 64px;
    letter-spacing: 10px;
    margin-bottom: 32px;
    display: flex;
    justify-content: space-evenly;
  }

  .loading-text span {
    animation: moveLetters 2.4s infinite ease-in-out;
    transform: translateX(0);
    position: relative;
    display: inline-block;
    opacity: 0;
    text-shadow: 0px 2px 10px rgba(46, 74, 81, 0.3); 
  }

  .loading-text span:nth-child(1) {
    animation-delay: 0.1s;
  }

  .loading-text span:nth-child(2) {
    animation-delay: 0.2s;
  }

  .loading-text span:nth-child(3) {
    animation-delay: 0.3s;
  }

  .loading-text span:nth-child(4) {
    animation-delay: 0.4s;
  }

  .loading-text span:nth-child(5) {
    animation-delay: 0.5s;
  }

  .loading-text span:nth-child(6) {
    animation-delay: 0.6s;
  }

  .loading-text span:nth-child(7) {
    animation-delay: 0.7s;
  }

  @keyframes moveLetters {
    0% {
      transform: translateX(-15vw);
      opacity: 0;
    }
    33.3%, 66% {
      transform: translateX(0);
      opacity: 1;
    }
    100% {
      transform: translateX(15vw);
      opacity: 0;
    }
  }

  .socials {
    position: fixed;
    bottom: 16px;
    right: 16px;
    display: flex;
    align-items: center;
  }

  .social-link {
    color: #fff;
    display: flex;
    align-items: center;
    cursor: pointer;
    text-decoration: none;
    margin-right: 12px;
  }
`;
