import React, { useState } from 'react'
import { useEffect } from 'react'
import { gsap } from 'gsap/gsap-core'

import { ScrollTrigger } from "gsap/ScrollTrigger";
import axios from 'axios';



const RelatedProducts = () => {
   
  const [data, setData] = useState([])

  const fetchData = async() => {
    try {
     
      const res = await axios.get(`https://infinia-backend.onrender.com/related`)
      if(res.data.success){
      
        setData(res.data.data)
        console.log("res",res.data.data);
      }
    } catch (error) {
      console.log(error.message);
      
    }
       }
    
      useEffect(()=> {
        fetchData()
      },[])
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

useEffect(()=>{
  gsap.registerPlugin(ScrollTrigger) 
  gsap.from(".box", {
    scale:0,
    opacity:0,
    duration:2,
    stagger:0.3,
    scrollTrigger:{
        trigger:".box",
        toggleActions:"restart none none none",
    }
})
},[])


  return (

    <div>
     <div className="text-center">
     <h1 className="text-3xl font-bold lg:-mt-10 md:-mt-32 md:-mb-10 sm:-mt-28 sm:-mb-10 ssm:-mt-20 ssm:-mb-10 border-b pl-4 ">Our Products</h1>
   </div>

<div className="bg-white bg-pro">
      <div className="mx-auto max-w-2xl px-4 py-16 sm:px-6 sm:py-24 lg:max-w-7xl lg:px-8">
        <h2 className="sr-only">Products</h2>

        <div className="grid grid-cols-1 gap-x-6 gap-y-10 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 xl:gap-x-8 sm:px-8 mv:px-8">
          {data.length>=0 && data.map((product) => (
            <a key={product.id} href={product.href} className="group">
              <div className="box aspect-h-1 h-[200px]  aspect-w-1 w-full overflow-hidden rounded-lg bg-gray-200 xl:aspect-h-8 xl:aspect-w-7">
                <img
                
                  src={product.image}
                  alt={product.imageAlt}
                  className="h-full w-full object-cover object-center rounded-lg shadow
                   img"
                />
              </div>
              <h3 className="mt-4 text-lg text-gray-700">{product.title}</h3>
              {/* <p className="mt-1 text-lg font-medium text-gray-900">{product.price}</p> */}
            </a>
          ))}
        </div>
      </div>
    </div>

    </div>
  )
}

export default RelatedProducts
