

import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Slider from 'react-slick';

import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import axios from 'axios';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: '40%',
    maxHeight: '90%',
    overflow: 'hidden',
    padding: '20px',
    backgroundColor: '#fff',
    borderRadius: '10px',
    boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)'
  }
};

const Image_Event = () => {
  const [modalIsOpen, setIsOpen] = useState(false);
  const [modalImage, setModalImage] = useState('');
  const [isDesktop, setIsDesktop] = useState(window.innerWidth > 992);

  const [data, setData] = useState([]);

  const fetchData = async () => {
    try {
      const res = await axios.get(
        "https://infinia-backend.onrender.com/events"
      );
      if (res.data.success) {
        setData(res.data.data);
        console.log("response", res.data.data);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const openModal = (image) => {
    setModalImage(image);
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const settings = {
    dots: false,
    infinite: true,
    speed: 300,
    slidesToShow: 4,
    slidesToScroll: 2,
    arrows: true,
    autoplay: true,
    autoplaySpeed: 6000,
    prevArrow: <button className="slick-prev fa-solid fa-arrow-left" aria-label="Prev"></button>,
    nextArrow: <button className="slick-next fa-solid fa-arrow-right" aria-label="Next"></button>,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

 

  return (
    <DIV>
      <div className="w-full overflow-hidden">
        <div className="property-wrapper mx-auto max-w-[1330px]">
     
          <h1>Featured Images</h1>
          <Slider {...settings}>
            {data.length>0 && data.map((property, index) => (
              <div className="property-item" key={index}>
                <a href="#" onClick={() => openModal(property.imgSrc)}>
                  <div className="property-img">
                    <img className='h-[200px]' src={property.imgSrc} alt="Featured Property" />
                  </div>
                  <div className="property-details">
                    <strong>{property.title}<br />{property.description}</strong><br />
                  </div>
                </a>
              </div>
            ))}
          </Slider>
        </div>
      </div>
      {/* {isDesktop && (
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          style={customStyles}
          contentLabel="Image Modal"
        >
          <button onClick={closeModal} style={{ float: 'right', background: 'none', border: 'none', fontSize: '1.5rem' }}>&times;</button>
          <img src={modalImage} alt="Modal" className='lg:w-[80%] lg:h-[40%] lg:ml-[50px]' />
        </Modal>
      )} */}
    </DIV>
  );
};

export default Image_Event;

const DIV = styled.div`
a:link,
a:visited,
a:hover,
a:active,
a:focus {
  text-decoration: none;
  outline: none;
}

.slick-track * {
  outline: none;
}

.property-wrapper {
  text-align: center;
  padding: 50px 0 75px;
}

.property-wrapper h1 {
  font: 400 40px "Playfair Display", serif;
  color: #212121;
  text-transform: uppercase;
}

.property-wrapper span {
  font: 500 13px/21px "Roboto", sans-serif;
  color: #680909;
  text-transform: uppercase;
  letter-spacing: 0.2em;
}

.property-slide {
  margin: 0 -7px;
}

.property-slide:not(.slick-initialized) .property-item:nth-child(n + 4) ~ div {
  display: none;
}

.property-item {
  display: inline-block;
  vertical-align: middle;
  width: calc(100% / 4);
  padding: 0 7px;
}

.property-item a {
  display: block;
  position: relative;
}

.property-slide {
  padding: 38px 0 0;
}

.property-slide button.slick-arrow {
  display: inline-block;
  position: absolute;
  width: 41px;
  height: 41px;
  top: 0;
  bottom: 0;
  margin: auto;
  transform: translateY(-40px);
  border: none;
  outline: none;
  background: transparent;  /* Ensure the button background is transparent */
}

.property-slide button:before {
  font-family: inherit !important;
  color: #000 !important;  /* Change arrow color to black */
  font-size: 30px;
}

.property-slide button.slick-prev {
  left: -50px;
}

.property-slide button.slick-next {
  right: -50px;
}

.property-item .property-img:before {
  content: "";
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: absolute;
  background: rgba(153, 126, 67, 0.3);
  opacity: 0;
  outline: 1px solid black;
  outline-offset: -10px;
  -webkit-transition: all 0.6s;
  -moz-transition: all 0.6s;
  -o-transition: all 0.6s;
  -ms-transition: all 0.6s;
  transition: all 0.6s;
}

.property-item .property-img:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: block;
  opacity: 0;
  background: rgba(255, 255, 255, 0.5);
  -webkit-transition: all 0.6s;
  -moz-transition: all 0.6s;
  -o-transition: all 0.6s;
  -ms-transition: all 0.6s;
  transition: all 0.6s;
}

.property-img {
  position: relative;
}

.property-img img {
  display: inline-block;
  width: 100%;
  height: auto;
}

.property-details {
  padding: 20px 0;
  opacity: 0;
  transition: all 0.3s ease;
}

.property-details strong {
  font: 500 20px "Roboto", sans-serif;
  color: #2f2f2f;
}

.property-item:hover .property-img:before,
.property-item:hover .property-img:after {
  opacity: 1;
}

.property-item a:hover .property-details {
  opacity: 1;
}
`;
