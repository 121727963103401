import React, { useState, useEffect } from "react";
import { stats } from "../data";
import axios from "axios";

const Stats = () => {
  const [data, setData] = useState({});
  const [animatedStats, setAnimatedStats] = useState(stats.map(item => ({ ...item, animatedValue: 0 })));

  const fetchData = async () => {
    try {
      const res = await axios.get("hhttps://infinia-backend.onrender.com/hero");
      if (res.data.success) {
        const fetchedData = res.data.data[0];
        setData(fetchedData);

        // Map the fetched data to the animatedStats array
        const updatedStats = [
          { text: fetchedData.num1Des, value: parseInt(fetchedData.num1.replace(/\D/g, '')) },
          { text: fetchedData.num2Des, value: parseInt(fetchedData.num2.replace(/\D/g, '')) },
          { text: fetchedData.num3Des, value: parseInt(fetchedData.num3.replace(/\D/g, '')) },
          { text: fetchedData.num4Des, value: parseInt(fetchedData.num4.replace(/\D/g, '')) }
        ].map(item => ({ ...item, animatedValue: 0 }));

        setAnimatedStats(updatedStats);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    const animationDuration = 1500;
    const intervalDuration = 50;
    const intervalId = setInterval(() => {
      const updatedStats = animatedStats.map(item => {
        const step = item.value / (animationDuration / intervalDuration);
        const newValue = Math.min(item.animatedValue + step, item.value);
        return { ...item, animatedValue: newValue };
      });
      setAnimatedStats(updatedStats);

      if (updatedStats.every(item => item.animatedValue === item.value)) {
        clearInterval(intervalId);
      }
    }, intervalDuration);

    return () => clearInterval(intervalId);
  }, [animatedStats]);

  return (
    <div className="bg-[#212121] lg:-mt-11 rounded-[20px] p-10 ssm:mt-10 ssm:p-5">
      <div className="flex flex-wrap gap-y-8">
        {animatedStats.map((item, index) => (
          <div
            className="min-h-[70px] w-3/6 flex flex-col justify-center lg:flex-1"
            key={index}
          >
            <div className="text-2xl font-semibold lg:text-4xl">
              {Math.round(item.animatedValue)}
              {item.value >= 100000 ? 'Lac+' : '+'}
            </div>
            <div className="text-base font-light max-w-[170px] mx-auto lg:text-md">
              {item.text}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Stats;
