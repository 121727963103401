import React, { useEffect, useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import RelatedProducts from "./RelatedProducts";
import image1 from "../assets/img/terracotta-jali-tiles-500x500.jpg";
import image2 from "../assets/img/terracotta-jali-500x500.jpg";
import image3 from "../assets/img/terracotta-jaali-500x500 (2).jpg";
import image4 from "../assets/img/clay-jali-helmet-jali-60d2f64017de7.jpg";
import gsap from "gsap";
import Transition from "../components/Transition";
import axios from "axios";

const images = [image1, image2, image3, image4];
const features = [
  { name: "Minimum Order Quantity", description: "500 Piece" },
  { name: "Country of Origin", description: "Made in India" },
  { name: "Position", description: "Exterior" },
  { name: "Product Type", description: "Terracotta" },
  { name: "Material", description: "Terracotta" },
  { name: "Color", description: "RED" },
  { name: "Feature", description: "Durable" },
  { name: "Shape", description: "Square" },
  { name: "Size", description: '8"*8"*3' },
  { name: "Usage/Application", description: "Apartment" },
  { name: "Weight", description: "2.5kg" },
];
const handleDownload = () => {
  window.open(`${process.env.PUBLIC_URL}/document-3a1000009497.pdf`, "_blank");
};
function Jails() {
  const imageRefs = useRef(images.map(() => React.createRef()));
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [data, setData] = useState([]);

  const location = useLocation();

  const categoryyy = location.pathname.slice(1);
  console.log("cate", categoryyy);
  const fetchData = async () => {
    try {
      const res = await axios.get(
        `https://infinia-backend.onrender.com/product/${categoryyy}`
      );
      if (res.data.success) {
        setData(res.data.data[0]);
        console.log("jali", res.data.data[0]);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    imageRefs.current.forEach((ref, index) => {
      const image = ref.current;
      gsap.from(image, {
        opacity: 0,
        y: 20,
        duration: 1,
        delay: index * 0.2,
        ease: "power3.out",
      });

    

      return () => {
        image.removeEventListener("mouseenter", () => {});
        image.removeEventListener("mouseleave", () => {});
        gsap.killTweensOf(image);
      };
    });
  }, []);

  return (
    <>
      <div>
        <div className="bg-white bg-pro">
          <div className="mx-auto grid max-w-2xl grid-cols-1 items-center gap-x-8 gap-y-16 px-4 py-24 sm:px-6 sm:py-32 lg:max-w-7xl lg:grid-cols-2 lg:px-8">
            <div>
              <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                {data.title}
              </h2>
              <p className="mt-4 text-gray-500">{data.description}</p>

              <div className="mt-3 flex gap-4" onClick={handleDownload}>
                <a
                  href="https://pdf.indiamart.com/impdf/2849033794291/101519832/document-3a1000009497.pdf#ITEM_DOC#"
                  download
                  target="_blank"
                  className="flex items-center gap-2 subtitle  hover:cursor-pointer"
                >
                  <img
                    width="50"
                    height="50"
                    className="mr-5"
                    src="https://upload.wikimedia.org/wikipedia/commons/thumb/8/87/PDF_file_icon.svg/267px-PDF_file_icon.svg.png"
                    alt="pdf"
                  />
                  Product Brochure
                </a>
              </div>

              <dl className="grid grid-cols-1 gap-x-6 gap-y-10 sm:grid-cols-2 sm:gap-y-16 lg:gap-x-8">
                <div key={data.name} className="border-t border-gray-200 pt-4">
                  <dt className="font-medium text-gray-900">
                    Country Of Origin
                  </dt>
                  <dd className="mt-2 text-sm text-gray-500">
                    {data.CountryOfOrigin}
                  </dd>
                </div>
                <div className="border-t border-gray-200 pt-4">
                  <dt className="font-medium text-gray-900">Position</dt>
                  <dd className="mt-2 text-sm text-gray-500">
                    {data.Position}
                  </dd>
                </div>
                <div className="border-t border-gray-200 pt-4">
                  <dt className="font-medium text-gray-900">
                    Product Type material
                  </dt>
                  <dd className="mt-2 text-sm text-gray-500">
                    {data.ProductType}
                  </dd>
                </div>
                <div className="border-t border-gray-200 pt-4">
                  <dt className="font-medium text-gray-900">Material</dt>
                  <dd className="mt-2 text-sm text-gray-500">
                    {data.material}
                  </dd>
                </div>
             
                <div className="border-t border-gray-200 pt-4">
                  <dt className="font-medium text-gray-900">Feature</dt>
                  <dd className="mt-2 text-sm text-gray-500">
                    {data.Feature}
                  </dd>
                </div>

                <div className="border-t border-gray-200 pt-4">
                  <dt className="font-medium text-gray-900">Shape</dt>
                  <dd className="mt-2 text-sm text-gray-500">
                    {data.shape}
                  </dd>
                </div>

                <div className="border-t border-gray-200 pt-4">
                  <dt className="font-medium text-gray-900">Size</dt>
                  <dd className="mt-2 text-sm text-gray-500">
                    {data.size}
                  </dd>
                </div>
                
                <div className="border-t border-gray-200 pt-4">
                  <dt className="font-medium text-gray-900">Usage Application</dt>
                  <dd className="mt-2 text-sm text-gray-500">
                    {data.UsageApplication}
                  </dd>
                </div>

                
                <div className="border-t border-gray-200 pt-4">
                  <dt className="font-medium text-gray-900">Weight</dt>
                  <dd className="mt-2 text-sm text-gray-500">
                    {data.Weight}
                  </dd>
                </div>

              </dl>
            </div>


            
            {/* <div className="grid grid-cols-2  grid-rows-2 gap-4 sm:gap-6 lg:gap-8 lg:mt-[-350px]">
              {images.map((image, index) => (
                <div
                  key={index}
                  ref={imageRefs.current[index]}
                  className="rounded-lg bg-gray-100 overflow-hidden"
                >
                  <img
                    src={image}
                    className="rounded-lg bg-gray-100 shadow img"
                    alt={`Terracotta Jali ${index + 1}`}
                  />
                </div>
              ))}
            </div> */}

<div className="grid grid-cols-2 grid-rows-2 gap-4 m-auto mt-11 sm:gap-6 lg:gap-8 ">
          <img
            src={data.image1}
            alt="Walnut card tray with white powder coated steel divider and 3 punchout holes."
            className="rounded-lg bg-gray-100 m-auto mt-6.75rem shadow img" 
          />
          <img
            src={data.image2}
            alt="Top down view of walnut card tray with embedded magnets and card groove."
            className="rounded-lg bg-gray-100 m-auto mt-6.75rem shadow img"
          />
           <img
            src={data.image3}
            alt="Top down view of walnut card tray with embedded magnets and card groove."
            className="rounded-lg bg-gray-100 m-auto mt-6.75rem shadow img"
          />
          <img
            src={data.image4}
            alt="Top down view of walnut card tray with embedded magnets and card groove."
            className="rounded-lg bg-gray-100 m-auto mt-6.75rem shadow img"
          />
         
         
        </div>
          </div>
        </div>
      </div>
      <RelatedProducts />
    </>
  );
}

export default Transition(Jails);
