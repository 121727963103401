import { Link, useLocation, useParams } from "react-router-dom";

import RelatedProducts from "./RelatedProducts";
import Transition from "../components/Transition";

import axios from "axios";
import { useState } from "react";
import { useEffect } from "react";

const features = [
  { name: "Minimum Order Quantity", description: "250 feet" },
  { name: "Color", description: "Ivory , Dark Brown ,Grey, Black" },
  { name: "Material", description: "wood plastic compit" },
  { name: "Style", description: "Rectangular" },
  { name: "Grade Of Material", description: "premium" },
  { name: "Usage/Application", description: "For Construction & Furnitures" },
  { name: "Type", description: "Indoor, Outdoor" },
];
const handleDownload = () => {

 
  window.open(`${process.env.PUBLIC_URL}/INFINIA WPC DOORS & DOOR FRAMES BROCHURE.pdf`, '_blank');
};
     function SingleProducts() {
    
      const location = useLocation()

const categoryyy = location.pathname.slice(1);
console.log("cate",categoryyy);


  useEffect(() => {
    window.scrollTo(0, 0);
  }, []); 
  
  
  const [data, setData] = useState([])

  const fetchData = async() => {
    try {
     
      const res = await axios.get(`https://infinia-backend.onrender.com/product/${categoryyy}`)
      if(res.data.success){
      
        setData(res.data.data)
        console.log("res",res.data.data);
      }
    } catch (error) {
      console.log(error.message);
      
    }
       }
    
      useEffect(()=> {
        fetchData()
      },[])

  return (
  <>

 <div className="mt-[-76px]">
  {data.length>=0 && data.map((product,index)=>{
    return <div  key={index} className="bg-white bg-pro ">
      <div className="mx-auto grid max-w-2xl grid-cols-1 items-center gap-x-8 gap-y-16 px-4 py-24 sm:px-6 sm:py-32 lg:max-w-7xl lg:grid-cols-2 lg:px-8">
        <div>
          <h1 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
        {product.title}
          </h1>
          <p className="mt-4 text-gray-500">
          {product.description}
          </p>

          <div className="mt-3 flex gap-4 "onClick={handleDownload}>
            <a
              href="https://res.cloudinary.com/dnevtbn0x/image/upload/v1722013209/uf8d5hsao7vhxlearjaj.webp"
              download
              target="_blank"
              className="flex items-center gap-2 subtitle  hover:cursor-pointer"
            >
              <img
                width="50"
                height="50"
                className="mr-5"
                src="https://upload.wikimedia.org/wikipedia/commons/thumb/8/87/PDF_file_icon.svg/267px-PDF_file_icon.svg.png"
                alt="pdf"
              />
              Product Brochure
            </a>
          </div>

     

          <dl className="grid grid-cols-1 gap-x-6 gap-y-10 sm:grid-cols-2 sm:gap-y-16 lg:gap-x-8">
          <div key={product.orderQantity} className="border-t border-gray-200 pt-4">
                <dt className="font-medium text-gray-900">Minimum Order Quantity</dt>
                <dd className="mt-2 text-sm text-gray-500">
                  {product.orderQantity}
                </dd>
                
              </div>

              <div key={product.color} className="border-t border-gray-200 pt-4">
                <dt className="font-medium text-gray-900">Color</dt>
                <dd className="mt-2 text-sm text-gray-500">
                  {product.color}
                </dd>
                
              </div>

              <div  className="border-t border-gray-200 pt-4">
                <dt className="font-medium text-gray-900">Material</dt>
                <dd className="mt-2 text-sm text-gray-500">
                  {product.material}
                </dd>
                
              </div>
              <div className="border-t border-gray-200 pt-4">
                <dt className="font-medium text-gray-900">Style</dt>
                <dd className="mt-2 text-sm text-gray-500">
                  {product.Pattern}
                </dd>
                
              </div>

              <div className="border-t border-gray-200 pt-4">
                <dt className="font-medium text-gray-900">Garde Of Material</dt>
                <dd className="mt-2 text-sm text-gray-500">
                  {product.gradeOfMaterial}
                </dd>
                
              </div>
              <div className="border-t border-gray-200 pt-4">
                <dt className="font-medium text-gray-900">Usage/Application</dt>
                <dd className="mt-2 text-sm text-gray-500">
                  {product.UsageApplication}
                </dd>
                
              </div>
              <div className="border-t border-gray-200 pt-4">
                <dt className="font-medium text-gray-900">Type</dt>
                <dd className="mt-2 text-sm text-gray-500">
                  {product.type}
                </dd>
                
              </div>
          </dl>
        </div>
        {/* iamges Side */}
        {data.length>=0 && data.map(()=>{
          
        })}
        <div className="grid grid-cols-2  grid-rows-2 gap-4 sm:gap-6 lg:gap-8">
     {product.image1 &&   <img
            src={product.image1}
            alt="Walnut card tray with white powder coated steel divider and 3 punchout holes."
            className="rounded-lg bg-gray-100 shadow img w-[277px] h-[277px]"
          /> }    
            {product.image2 &&   <img
            src={product.image2}
            alt="Walnut card tray with white powder coated steel divider and 3 punchout holes."
            className="rounded-lg bg-gray-100 shadow img w-[277px] h-[277px]"
          /> }
             {product.image3 &&   <img
            src={product.image3}
            alt="Walnut card tray with white powder coated steel divider and 3 punchout holes."
            className="rounded-lg bg-gray-100 shadow img w-[277px] h-[277px]"
          /> }
             {product.image4 &&   <img
            src={product.image4}
            alt="Walnut card tray with white powder coated steel divider and 3 punchout holes."
            className="rounded-lg bg-gray-100 shadow img w-[277px] h-[277px]"
          /> }
        </div>
         {/* iamges Side */}
      </div>
    </div>
  })}
        


    </div>

  
    <RelatedProducts />
    </>
  );
}

export default Transition(SingleProducts);
