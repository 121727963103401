import React, { useEffect, useState } from "react";

import { newInStore } from "../data";

import NewItemsSlider from "./NewItemsSlider";
import axios from "axios";

const NewItems = () => {
  const { title, subtitle, link, icon } = newInStore;
  const [data, setData] = useState({})

  const fetchData = async() => {
    try {
      const res = await axios.get("https://infinia-backend.onrender.com/offering")
      if(res.data.success){
      
        setData(res.data.data[0])
      }
    } catch (error) {
      console.log(error.message);
      
    }
       }
    
      useEffect(()=> {
        fetchData()
      },[])

  
  return (
    <section className=" ssm:mt-6 xl:mt-12 ">
      <div className=" w-[100%]">
        <div className="flex flex-col lg:flex-row lg:p-5 justify-between">   
       
          <div className="flex md:flex-col items-baseline gap-x-6 mb-6 lg:mb-0  lg:w-[20%] 
          ">
          <h2 className="title max-w-[245px] ssm:ml-6">{data.title}</h2>
          <p className="max-w-[245px] lg:mb-12">{data.description}</p>
          <div className="hidden lg:flex items-center border-2 p-2 ml-2 bg-orange-500 rounded-md">
            <a className=" border-primary lg:items-center font-medium transition-all" href="#">{data.buttonText}</a>
            <div className="text-3xl">
              {icon}
            </div>
          </div>
         
          </div>
           {/* slider */}
           <div className="lg:w-[75%] xl:w-[70%] lg:right-24 ">
        <NewItemsSlider slider_data={data.images}/>
           </div>
        </div> 
      </div>
    </section>
  );
};

export default NewItems;