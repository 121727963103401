import React, { Suspense, useEffect, useState } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import MainRoutes from "./components/MainRoutes";
import Footer from "./components/Footer";
import Home1 from './components/Home1';
import Whatsapp from './components/Whatsapp';
import Loading from './pages/Loading';
import axios from 'axios';

// Lazy load the OtherComponent
const OtherComponent = React.lazy(() => import('./components/OtherComponent'));

const App = () => {
  const [isLoading, setIsLoading] = useState(true); 
  const [show, setShow] = useState(true);

  const fetchData = async () => {
    try {
      const res = await axios.get("https://infinia-backend.onrender.com/hero");
      if (res.data) {
     console.log("res",res);
     
        setIsLoading(false); 
      }
    } catch (error) {
      console.log(error.message);
      setIsLoading(false); 
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShow(false);
    }, 2000);

 
    return () => clearTimeout(timer);
  }, []);

  if (isLoading || show) {
    return <Loading />; 
  }

  return (
    <HelmetProvider>
      <div className="w-full">
        <Helmet>
          <title>Infinia Industries</title>
          <meta name="description" content="Infinia Industries Pvt Ltd is Central India's Premier Manufacturer of Innovative Building Solutions, specializing in Wood Polymer Composite, Regenerated Plastic, and Terra Cotta products." />
          <meta name="keywords" content="Infinia Industries, Wood Polymer Composite, Regenerated Plastic, Terra Cotta, Building Solutions" />
          <script type="application/ld+json">
            {`
              {
                "@context": "https://schema.org",
                "@type": "WebPage",
                "name": "Infinia Industries",
                "description": "Infinia Industries Pvt Ltd Central India's Premier Manufacturer of Innovative Building Solutions! We take pride in being the forefront of innovation, carving a niche as the leading manufacturer of Wood Polymer Composite, Regenerated Plastic, and Terra Cotta products in Central India."
              }
            `}
          </script>
        </Helmet>

        <Home1 />
        
        <Suspense fallback={<div>Loading component...</div>}>
          
        </Suspense>

        <Whatsapp />
        <MainRoutes />
        <Footer />
      </div>
    </HelmetProvider>
  );
};

export default App;
