import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
// import components
import TestimonialSlider from "./TestimonialSlider";

// import data
import axios from "axios";

// deStructure testimonial
const Testimonial = () => {
  const [data, setData] = useState([]);

  const fetchData = async () => {
    try {
      const res = await axios.get(
        "https://infinia-backend.onrender.com/testimonial"
      );
      if (res.data.success) {
        setData(res.data.data);
      
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  if (data.length >= 0) {
    console.log("images", data);
  }

  return (
    <section className="section test_bg lg:mt-[40px]">
      <div className="container mx-auto">
        <div className="flex flex-col lg:flex-row lg:gap-x-10 gap-y-2">
          <motion.div
            initial={{
              x: "-200px",
              opacity: 0,
            }}
            whileInView={{
              x: 0,
              opacity: 1,
            }}
            transition={{
              duration: 1,
            }}
            className="lg:max-w-[50%]"
          >
            {/* title */}
            <h2 className="title mb-9">What people are saying about us</h2>
            {/* sliders */}
            <TestimonialSlider />
          </motion.div>
          {/* image */}
          <motion.div
            initial={{
              x: "200px",
              opacity: 0,
            }}
            whileInView={{
              x: 0,
              opacity: 1,
            }}
            transition={{
              duration: 1,
            }}
            className="order-1 rounded-lg"
          >
            {data.length > 0 && data[0].image ? (
              <img className="rounded-lg img" src={data[0].image} alt="Testimonial" />
            ) : (
              <p>No image available</p>
            )}
          </motion.div>
        </div>
      </div>
    </section>
  );
};

export default Testimonial;
