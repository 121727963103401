import React, { useEffect, useState } from 'react';
import { Swiper, SwiperSlide} from "swiper/react"
import "swiper/css"
import "swiper/css/navigation"
import {Autoplay, Navigation} from "swiper";
import {testimonial} from "../data"
import styled from 'styled-components';
import axios from 'axios';

const TestimonialSlider = () => {
  const [data, setData] = useState([]);

  const fetchData = async () => {
    try {
      const res = await axios.get(
        // "https://infinia-kappa.vercel.app/testimonial"
        "https://infinia-backend.onrender.com/testimonial"
      );
      if (res.data.success) {
        setData(res.data.data);
        console.log("response", res.data.data);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);
  
  return  <DIV> <Swiper className='testimonialSlider'
 modules={[Navigation, Autoplay]}
  navigation={true}
  autoplay={true}
  >
    {data.length>0 && data.map((person, index)=> {
     
    
 return <SwiperSlide key={index}>
  <div className='flex flex-col min-h-[250px]'>
    <div className='flex items-center gap-x-5 mb-9'>
      {/* avatar */}
      <img src={person.icon} alt={person.title}/>
      <div>
        <div className='text-xl font-semibold'>{person.name}</div>
        <div className='text-gray-500'>{person.city}</div>
      </div>
    </div>
{/* text */}
<div className='text-xl max-w-[570px]'>{person.title}</div>
  </div>
 </SwiperSlide>
    })}
  </Swiper>
  </DIV>
};

export default TestimonialSlider;

const DIV = styled.div`

.testimonialSlider .swiper-button-prev:hover,
.testimonialSlider .swiper-button-next:hover {
  background: #286f6c;
  transition: all 0.25s ease-in-out;
}

.testimonialSlider .swiper-button-prev:after,
.testimonialSlider .swiper-button-next:after {
  font-size: 16px;
  color: white;
}

.testimonialSlider .swiper-button-prev:hover,
.testimonialSlider .swiper-button-next:hover {
  background: #286f6c;
  transition: all 0.25s ease-in-out;
}

.testimonialSlider .swiper-button-prev:after,
.testimonialSlider .swiper-button-next:after {
  font-size: 16px;
  color: white;
}

.testimonialSlider .swiper-button-prev,
.testimonialSlider .swiper-button-next {
  background: #23262f;
  box-shadow: 0px 4px 10px rgba(15, 27, 51, 0.1);
  top: 85%;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  z-index: 20;
}

.testimonialSlider .swiper-button-prev {
  left: 0;
}

.testimonialSlider .swiper-button-next {
  left: 12%;
}

   .testimonialSlider .swiper-button-prev {
 margin-left: 10px;
 /* margin-top: .1px; */
 height: 40px;
 width: 40px;
}
    .testimonialSlider .swiper-button-next {
 margin-left: 30px;

 height: 40px;
 width: 40px;
}
`
