import React,{useEffect, useState} from 'react'
import RelatedProducts from './RelatedProducts'
import { Link, useLocation } from 'react-router-dom';
 
import Transition from '../components/Transition';
import axios from 'axios';
const features = [
  { name: "Size", description: "75 X 35 mm , 108 X 35mm , 50 X 25mm, 50 X 30mm" },
  { name: "Board Material", description: "Popular" },
 
  { name: "Color", description: "WALNUT, TEAKWOOD, PEARL WHITE,PINEWOOD" },
  { name: "Standard Length", description: "8' , 10' , 12' , 14' feet" },
 
];
const handleDownload = () => {
  window.open(`${process.env.PUBLIC_URL}/UPR BROCHURE.pdf`, '_blank');
};

const UPR_Sliding_Window = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const location = useLocation()

  const categoryyy = location.pathname.slice(1);
  console.log("cate",categoryyy);
  const [data, setData] = useState([])

  const fetchData = async() => {
    try {
     
      const res = await axios.get(`https://infinia-backend.onrender.com/product/${categoryyy}`)
      if(res.data.success){
      
        setData(res.data.data[0])
        console.log("ressssf",res.data.data[0]);
      }
    } catch (error) {
      console.log(error.message);
      
    }
       }
    
      useEffect(()=> {
        fetchData()
      },[])
  return (
    <>
 <div className="relative mb-10">
   <div
        className="absolute top-0 left-0 w-full h-100 bg-cover bg-center"
        style={{
          backgroundImage: 'url("https://img.freepik.com/free-vector/door-realistic-composition-with-front-view-modern-room-interior-with-armchair-frames-closed-door-vector-illustration_1284-74376.jpg?w=740&t=st=1708160206~exp=1708160806~hmac=0a5fae92a496cddbcb7d6565b2eb37c81e1190c7813988b837e29735fd07159f")',
        }}
      ></div>
           <div className="bg-white bg-pro">
      <div className="mx-auto grid max-w-2xl grid-cols-1 items-center gap-x-8 gap-y-16 px-4 py-24 sm:px-6 sm:py-32 lg:max-w-7xl lg:grid-cols-2 lg:px-8">
        <div>
          <h1 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
          {data.title}
          </h1>
          <p className="mt-4 text-gray-500">
        {data.description}
          </p>

         

          <div className="mt-3 flex gap-4" onClick={handleDownload}>
            <a
              href="../../public/UPR BROCHURE.pdf"
              download
              target="_blank"
              className="flex items-center gap-2 subtitle  hover:cursor-pointer"
            >
              <img
                width="50"
                height="50"
                className="mr-5"
                src="https://upload.wikimedia.org/wikipedia/commons/thumb/8/87/PDF_file_icon.svg/267px-PDF_file_icon.svg.png"
                alt="pdf"
              />
              Product Brochure
            </a>
          </div>

    

          <dl className="grid grid-cols-1 gap-x-6 gap-y-10 sm:grid-cols-2 sm:gap-y-16 lg:gap-x-8">
          
          <div className="border-t border-gray-200 pt-4">
                <dt className="font-medium text-gray-900">size</dt>
                <dd className="mt-2 text-sm text-gray-500">
                  {data.size}
                </dd>
              </div>

              <div  className="border-t border-gray-200 pt-4">
                <dt className="font-medium text-gray-900">Board Material
                </dt>
                <dd className="mt-2 text-sm text-gray-500">
                  {data.gradeOfMaterial}
                </dd>
              </div>
              <div  className="border-t border-gray-200 pt-4">
                <dt className="font-medium text-gray-900">Color
                </dt>
                <dd className="mt-2 text-sm text-gray-500">
                  {data.color}
                </dd>
              </div>
              <div  className="border-t border-gray-200 pt-4">
                <dt className="font-medium text-gray-900">Standard Length
                </dt>
                <dd className="mt-2 text-sm text-gray-500">
                  {data.Dimensions}
                </dd>
              </div>
        
          </dl>
        </div>
        <div className="grid grid-cols-2  grid-rows-2 gap-4 sm:gap-6 lg:gap-8">
          <img
            src={data.image1}
            alt="Walnut card tray with white powder coated steel divider and 3 punchout holes."
            className="rounded-lg bg-gray-100 shadow img w-[277px] h-[277px]"
          />
          <img
            src={data.image2}
            alt="Top down view of walnut card tray with embedded magnets and card groove."
            className="rounded-lg bg-gray-100 shadow img w-[277px] h-[277px]"
          />
          <img
            src={data.image4}
            alt="Side of walnut card tray with card groove and recessed card area."
            className="rounded-lg bg-gray-100 shadow img w-[277px] h-[277px]"
          />
          <img
            src={data.image3}
            alt="Walnut card tray filled with cards and card angled in dedicated groove."
            className="rounded-lg bg-gray-100 shadow img w-[277px] h-[277px]"
          />
        </div>
      </div>
    </div>
    </div>
    <RelatedProducts />
    </>
  )
}

export default Transition(UPR_Sliding_Window)

