import React, { useEffect, useState } from 'react';
import { Link, useLocation } from "react-router-dom";
import image1 from '../assets/img/flyashbrick.jpg'
import image2 from '../assets/img/flyash-brick-1.jpg'
import image3 from '../assets/img/fly-ash-bricks (1).jpg'
import image4 from '../assets/img/cement-blocks-3 - 500x500.jpg'
import Transition from "../components/Transition";
import axios from 'axios';
import RelatedProducts from './RelatedProducts';



  const handleDownload = () => {
    window.open(`${process.env.PUBLIC_URL}/Fly Ash Bricks Pallets.pdf`, '_blank');
  };


const Flyash_Brick_Pallets = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);


  const location = useLocation()

  const categoryyy = location.pathname.slice(1);
  console.log("cate",categoryyy);
  const [data, setData] = useState([])

  const fetchData = async() => {
    try {
     
      const res = await axios.get(`https://infinia-backend.onrender.com/product/${categoryyy}`)
      if(res.data.success){
      
        setData(res.data.data[0])
        console.log("ressss",res.data.data[0]);
      }
    } catch (error) {
      console.log(error.message);
      
    }
       }
    
      useEffect(()=> {
        fetchData()
      },[])
  return (
    
    <div className="bg-white bg-pro">
      <div className="mx-auto grid max-w-2xl grid-cols-1 items-center gap-x-8 gap-y-16 px-4 py-24 sm:px-6 sm:py-32 lg:max-w-7xl lg:grid-cols-2 lg:px-8">
        <div>
          <h1 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
          {data.title}
          </h1>
          <p className="mt-4 text-gray-500">
           {data.description}
          </p>

          <div className="mt-2 flex gap-4" onClick={handleDownload}>
            <Link
              to="../../public/Fly Ash Bricks Pallets.pdf"
              download
              target="_blank"
              className="flex items-center gap-2 subtitle  hover:cursor-pointer"
            >
              <img
                width="25"
                height="25"
                className="mr-5"
                src="https://upload.wikimedia.org/wikipedia/commons/thumb/8/87/PDF_file_icon.svg/267px-PDF_file_icon.svg.png"
                alt="pdf"
                
              />
              Product Brochure
            </Link>
          </div>

    

          <dl className="grid grid-cols-1 gap-x-6 gap-y-10 sm:grid-cols-2 sm:gap-y-16 lg:gap-x-8">
          <div key={data.name} className="border-t border-gray-200 pt-4">
                <dt className="font-medium text-gray-900">Minimum Order Quantity</dt>
                <dd className="mt-2 text-sm text-gray-500">
                  {data.orderQantity}
                </dd>
              </div>
              <div className="border-t border-gray-200 pt-4">
                <dt className="font-medium text-gray-900">Material</dt>
                <dd className="mt-2 text-sm text-gray-500">
                  {data.CountryOfOrigin}
                </dd>
              </div>
              <div  className="border-t border-gray-200 pt-4">
                <dt className="font-medium text-gray-900">Color</dt>
                <dd className="mt-2 text-sm text-gray-500">
                  {data.color}
                </dd>
              </div>
              <div  className="border-t border-gray-200 pt-4">
                <dt className="font-medium text-gray-900">Color</dt>
                <dd className="mt-2 text-sm text-gray-500">
                  {data.Pattern}
                </dd>
              </div>
              <div  className="border-t border-gray-200 pt-4">
                <dt className="font-medium text-gray-900">Material</dt>
                <dd className="mt-2 text-sm text-gray-500">
                  {data.material}
                </dd>
              </div>
              <div  className="border-t border-gray-200 pt-4">
                <dt className="font-medium text-gray-900">Usage/Application</dt>
                <dd className="mt-2 text-sm text-gray-500">
                  {data.UsageApplication
                  }
                </dd>
              </div>
          </dl>
        </div>
        <div className="grid grid-cols-2 grid-rows-2 gap-4 m-auto mt-11 sm:gap-6 lg:gap-8 ">
          <img
            src={image1}
            alt="Walnut card tray with white powder coated steel divider and 3 punchout holes."
            className="rounded-lg bg-gray-100 m-auto mt-6.75rem shadow img" 
          />
          <img
            src={image2}
            alt="Top down view of walnut card tray with embedded magnets and card groove."
            className="rounded-lg bg-gray-100 m-auto mt-6.75rem shadow img"
          />
           <img
            src={image3}
            alt="Top down view of walnut card tray with embedded magnets and card groove."
            className="rounded-lg bg-gray-100 m-auto mt-6.75rem shadow img"
          />
          <img
            src={image4}
            alt="Top down view of walnut card tray with embedded magnets and card groove."
            className="rounded-lg bg-gray-100 m-auto mt-6.75rem shadow img"
          />
         
         
        </div>
      </div>
      <RelatedProducts />
    </div>
  );
};

export default Transition(Flyash_Brick_Pallets);
